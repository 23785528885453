import React from "react"
import { graphql } from "gatsby"

import { About as Page } from "../components/About/About"

import { useHotjar } from '../hooks/useHotjar'

export const query = graphql`
  query {
    page: sanityPageAbout {
      title
      content: _rawContent(resolveReferences: { maxDepth: 8 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => {
  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("about_page")

  return <Page {...props} {...data} />
}
export default Component
